import { request } from 'gaxios';
import { RuleType } from 'features/Rules';
import { useRequestWithTokenAndTransformer } from 'hooks/useWithToken';
import { RuleTypeDiff, UploadedRules } from './UploadRulesDiff';

type RuleServiceParamsType = {
  refetchAfter: string;
  policy: string;
  pageLimit?: number;
  page?: number;
  searchValue?: string;
  sort?: string;
  multiplePolicies?: string[];
  month?: string;
  year?: number;
};

type RulesResponse = {
  rules: RuleType[];
  totalRules: number;
  pageLimit: number;
  page: number;
  searchValue?: string;
  multiplePolicies?: string[];
};

export const useFetchRules = (params: RuleServiceParamsType) => {
  const { refetchAfter, searchValue, policy, pageLimit, page, sort, multiplePolicies, month, year } = params;
  const { data, loading } = useRequestWithTokenAndTransformer<RulesResponse, RulesResponse>(
    {
      url: `/api/redirect/rules/${policy}`,
      params: {
        search: searchValue,
        pageLimit,
        page,
        sort,
        multiplePolicies: JSON.stringify(multiplePolicies),
        month,
        year,
      },
    },
    [pageLimit, page, searchValue, refetchAfter, sort],
    ({ rules, totalRules, pageLimit, page }) => ({ rules, totalRules, pageLimit, page })
  );
  return { data, loading };
};

export const fetchUploadedRulesByIds = async (
  policy: string,
  id: string,
  docIds: string[],
  token: string
): Promise<{
  uploadedRules: RuleTypeDiff[];
}> => {
  const response =
    docIds.length > 0 &&
    (await request({
      url: `/api/redirect/rules/${policy}/upload-diff/${id}`,
      method: 'POST',
      data: { docIds: docIds.slice(0, 30) },
      headers: { Authorization: `Bearer ${token}` },
    }));
  if (!response) {
    throw new Error('No ids provided!');
  }
  return { uploadedRules: (response.data as UploadedRules).uploadedRules as RuleTypeDiff[] };
};
