import React, { FC, useContext } from 'react';
import { Context as RulesContext } from '../../hooks/contexts/RulesContext';
import { useFetchRules } from '../../pages/RulesPage/rules.service';
import { Rules } from '../Rules';
import { Column as RuleTableColumn } from '../../types';
import { getPreviousMonthAndYear } from '../../utils/dates';

type RuleInvocationsParams = {
  policy: string;
  direction: 'asc' | 'desc';
  multiplePolicies?: string[];
};

const PAGE_LIMIT = 10;

export const RuleInvocations: FC<RuleInvocationsParams> = ({ policy, direction, multiplePolicies }) => {
  const {
    state: { refetchAfter, sort },
  } = useContext(RulesContext);
  const filterMonth = getPreviousMonthAndYear(new Date());

  const { data, loading } = useFetchRules({
    refetchAfter,
    policy,
    pageLimit: PAGE_LIMIT,
    sort: `${direction}-${sort.field ?? 'invocations'}`,
    multiplePolicies,
    month: filterMonth.month,
    year: filterMonth.year,
  });
  const { rules } = data ?? { rules: [], totalRules: 0 };
  const visibleColumns: RuleTableColumn[] = [
    {
      label: 'From',
      value: 'matchURL',
      visible: true,
      field: 'matchURL',
      width: 60,
    },
    {
      label: 'To',
      value: 'targetURL',
      visible: true,
      field: 'targetURL',
      width: 60,
    },
    { label: 'Usage', value: 'invocations', visible: true, field: 'invocations' },
    { label: 'Target status', value: 'targetStatus', visible: true, field: 'targetStatus' },
    { label: 'Actions', value: 'actions', visible: true },
  ];
  return (
    <Rules
      selectedRules={['']}
      isLoading={loading}
      rules={rules}
      onRuleToggle={() => null}
      toggleSelectAll={() => null}
      clearSelectedRules={() => null}
      columns={visibleColumns}
      policy={policy}
      hideActions={true}
      hasShowAllButton
      multiplePolicy={multiplePolicies}
      sortDirection={direction}
      pageLimit={PAGE_LIMIT}
    />
  );
};
