/* eslint-disable  @typescript-eslint/no-explicit-any */
import * as d3 from 'd3';
import { clone } from 'ramda';
import { GlobalDataNode } from './Global';
import { Info } from './TreeMap';

export const sites = [
  'ae/en',
  'at/de',
  'au/en',
  'be/fr',
  'be/nl',
  'bh/ar',
  'bh/en',
  'ca/en',
  'ca/fr',
  'ch/de',
  'ch/en',
  'ch/fr',
  'ch/it',
  'cz/cs',
  'de/de',
  'dk/da',
  'eg/en',
  'es/ca',
  'es/en',
  'es/es',
  'es/eu',
  'es/gl',
  'fi/fi',
  'fr/fr',
  'gb/en',
  'hr/hr',
  'hu/hu',
  'ie/en',
  'in/en',
  'it/it',
  'jo/ar',
  'jo/en',
  'jp/en',
  'jp/ja',
  'kr/en',
  'kr/ko',
  'kw/ar',
  'kw/en',
  'ma/ar',
  'ma/en',
  'ma/fr',
  'mx/en',
  'mx/es',
  'my/en',
  'my/ms',
  'nl/en',
  'nl/nl',
  'no/no',
  'pl/pl',
  'pt/en',
  'pt/pt',
  'qa/en',
  'ro/ro',
  'rs/sr',
  'sa/ar',
  'sa/en',
  'se/sv',
  'sg/en',
  'si/sl',
  'sk/sk',
  'th/en',
  'th/th',
  'ua/uk',
  'us/en',
];

export const height = 1000,
  rectW = 200,
  rectH = 100,
  rectSpacing = 20,
  duration = 250;

// declares a tree layout and assigns the size
const treemap = d3
  .tree()
  .nodeSize([rectW + rectSpacing, rectH + rectSpacing])
  .separation(function separation(a, b) {
    return a.parent === b.parent ? 1 : 1.25;
  });

let i = 0;

export const collapse = (d: Record<string, any>): void => {
  if (d.children) {
    d._children = d.children;
    d._children.forEach(collapse);
    d.children = null;
  }
};

const getNodeClasses = (data: any) => {
  let classes = 'node ';
  classes += data.children.length ? 'node-internal ' : 'node-leaf ';
  classes += `${data.owner} `;

  if (data.populated) {
    classes += 'populated ';
  }

  if ((!data.allowLocalPages && data.pages && data.pages.length > 0) || (!data.allowLocalSections && data.sections && data.sections.length > 0)) {
    classes += 'violation';
  }

  return classes;
};

export const updateTreeMap = (
  source: d3.HierarchyNode<GlobalDataNode> & { x0?: number; y0?: number; x?: number; y?: number },
  g: d3.Selection<SVGSVGElement, GlobalDataNode, null, undefined>,
  setInfo: (i: Info | null) => void
): void => {
  // Assigns the x and y position for the nodes
  const treeData = treemap(source);

  // Compute the new tree layout.
  const nodes = treeData.descendants(),
    links = treeData.descendants().slice(1);

  // Normalize for fixed-depth.
  nodes.forEach(function (d) {
    d.y = d.depth * 400;
  });

  // ****************** Nodes section ***************************

  // Update the nodes...
  const node = g.selectAll('g.node').data(nodes, function (d: any) {
    return d.id || (d.id = ++i);
  });

  // Enter any new nodes at the parent's previous position.
  const nodeEnter = node
    .enter()
    .append('g')
    .attr('class', function (d) {
      return getNodeClasses(d.data);
    })
    .attr('transform', function () {
      if (source.x0 && source.y0) {
        return `translate(${source.x0},${source.y0 + rectH})`;
      }
      return '';
    });

  nodeEnter
    .append('circle')
    .attr('r', (d: any) => (d.data.children.length ? 12 : 0))
    .attr('fill', 'black')
    .attr('transform', function () {
      return `translate(${rectW / 2},${rectH + 8})`;
    });

  nodeEnter
    .append('text')
    .attr('fill-opacity', (d: any) => (d.data.children.length ? 1 : 0))
    .attr('dy', 114)
    .attr('dx', 100)
    .attr('class', 'plus')
    .style('text-anchor', 'middle')
    .text('+');

  nodeEnter
    .append('rect')
    .attr('class', 'node')
    .attr('width', 0)
    .attr('height', 0)
    .attr('rx', 5)
    .attr('ry', 5)
    .on('click', click)
    .on('mouseover', (event, d: any) => {
      setInfo({ ...d.data });
    });

  // adds the text to the node
  nodeEnter
    .append('text')
    .attr('dy', 30)
    .attr('dx', 20)
    .attr('class', 'large')
    .style('text-anchor', 'start')
    .text((d: any) => {
      if (d.data.title.length > 18) {
        return `${d.data.title.substring(0, 18)}…`;
      } else {
        return d.data.title;
      }
    });

  nodeEnter
    .append('text')
    .attr('dy', 80)
    .attr('dx', 20)
    .attr('class', 'owner')
    .style('text-anchor', 'start')
    .text((d: any) => d.data.owner);

  nodeEnter
    .append('text')
    .attr('dy', 80)
    .attr('dx', rectW - 20)
    .attr('class', 'large')
    .style('text-anchor', 'end')
    .text((d: any) => (d.data.pages?.length ? d.data.pages.length : ''));

  nodeEnter
    .append('text')
    .attr('dy', 60)
    .attr('dx', rectW - 20)
    .attr('class', 'large')
    .style('text-anchor', 'end')
    .text((d: any) => (d.data.sections?.length ? d.data.sections.length : ''));

  // UPDATE
  const nodeUpdate = nodeEnter.merge(node as any);

  // Transition to the proper position for the node
  nodeUpdate
    .transition()
    .duration(duration)
    .attr('transform', (d: any) => `translate(${d.x},${d.y})`);

  // Update the node attributes and style
  nodeUpdate.select('rect.node').attr('width', rectW).attr('height', rectH);

  // Remove any exiting nodes
  const nodeExit = node
    .exit()
    .transition()
    .duration(duration)
    .attr('transform', () => `translate(${source.x0},${(source.y0 || 0) + rectH})`)
    .remove();

  // On exit shrink the rect
  nodeExit.select('rect').attr('width', 0).attr('height', 0);

  // On exit reduce the opacity of text and circle
  nodeExit.select('text').style('fill-opacity', 1e-6);
  nodeExit.select('circle').attr('r', 0);

  // ****************** links section ***************************

  // Update the links...
  const link = g.selectAll('path.link').data(links, function (d: any) {
    return d.id;
  });

  // Enter any new links at the parent's previous position.
  const linkEnter = link
    .enter()
    .insert('path', 'g')
    .attr('class', 'link')
    .attr('d', function () {
      const o = { x: source.x0, y: source.y0 };
      return diagonal(o, o);
    });

  // UPDATE
  const linkUpdate = linkEnter.merge(link as any);

  // Transition back to the parent element position
  linkUpdate
    .transition()
    .duration(duration)
    .attr('d', function (d) {
      return diagonal(d, d.parent);
    });

  // Remove any exiting links
  link
    .exit()
    .transition()
    .duration(duration)
    .attr('d', () => {
      const o = { x: source.x, y: source.y };
      return diagonal(o, o);
    })
    .remove();

  // Store the old positions for transition.
  nodes.forEach(function (d: any) {
    d.x0 = d.x;
    d.y0 = d.y;
  });

  // Creates a curved (diagonal) path from parent to the child nodes
  function diagonal(s: any, d: any) {
    return `M${s.x + rectW / 2},${s.y}C${s.x + rectW / 2},${(s.y + d.y) / 2} ${d.x + rectW / 2},${(s.y + d.y) / 2} ${d.x + rectW / 2},${d.y + rectH}`;
  }

  // Toggle children on click.
  function click(event: any, d: any) {
    if (d.children) {
      d._children = d.children;
      d.children = null;
    } else {
      d.children = d._children;
      d._children = null;
    }
    updateTreeMap(source, g, setInfo);
  }
};

const processTreeMapData = (node: GlobalDataNode, slug: string, leaf: string, url: string) => {
  const a = node.children.find((c) => c.slug === `${slug + leaf}/`);
  if (a) {
    a.populated = true;
  } else {
    /(-pub[a-z0-9]{8})$/.test(url) ? node.pages.push(url) : node.sections.push(url);
  }
};

const processTreeMapChildren = (node: GlobalDataNode, slug: string, leaf: string, url: string) => {
  if (node.slug === slug) {
    processTreeMapData(node, slug, leaf, url);
  }
  node.children.forEach((n) => processTreeMapChildren(n, slug, leaf, url));
};

const addUrl = (data: GlobalDataNode, slug: string, leaf: string, url: string) => {
  if (slug === '/') {
    slug = '';
    processTreeMapData(data, slug, leaf, url);
    return;
  }
  data.children.forEach((n) => {
    if (n.slug === url) {
      n.populated = true;
    }
    processTreeMapChildren(n, slug, leaf, url);
  });
};

export const addPages = (data: GlobalDataNode, pages: string[]): GlobalDataNode => {
  const newData = clone(data);
  pages.forEach((p) => {
    const hierarchy = p.split('/').filter((e) => e.length !== 0);
    if (hierarchy.length > 0) {
      const leaf = hierarchy.pop() || '';
      addUrl(newData, `${hierarchy.join('/')}/`, leaf, p);
    }
  });

  return newData;
};
