import React, { FC, useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import Button from '@ingka/button';
import arrowDown from '@ingka/ssr-icon/paths/arrow-down-to-line';
import arrowUp from '@ingka/ssr-icon/paths/arrow-up-to-line';
import trashCan from '@ingka/ssr-icon/paths/trash-can';
import Text from '@ingka/text';
import FileSaver from 'file-saver';
import { request } from 'gaxios';
import { CheckboxFields, CheckboxGroup } from 'components/CheckboxGroup';
import ContainerComp from 'components/common/Container';
import { Pagination } from 'components/Pagination';
import { SearchField } from 'components/Search';
import { Rules } from 'features/Rules';
import { DeleteRuleModal } from 'features/Rules/DeleteRuleModal';
import { Context as RulesContext } from 'hooks/contexts/RulesContext';
import { Context as LoginContext } from 'hooks/contexts/LoginContext';
import { Context as StatusMessageContext, StatusMessageState } from 'hooks/contexts/StatusMessageContext';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { Column as RuleTableColumn } from 'types';
import { useFetchRules } from './rules.service';
import * as Styled from 'pages/styles';
import { getPreviousMonthAndYear } from '../../utils/dates';
import { findCorrectPolicy } from '../../utils/policy';

type LocaleDetailProps = RouteComponentProps<{ policy: string; country: string }, {}, StatusMessageState | undefined>;
export const RulesPage: FC<LocaleDetailProps> = ({
  match: {
    params: { policy, country },
  },
  location: { search },
  history: {
    location: { state },
  },
}) => {
  const defaultColumns: RuleTableColumn[] = [
    { label: 'From', value: 'fromRule', visible: true, field: 'matchURL' },
    { label: 'To', value: 'toRule', visible: true, field: 'targetURL' },
    { label: 'Policy name', value: 'policyName', visible: true, field: 'name' },
    { label: 'Usage', value: 'invocations', visible: true, field: 'invocations' },
    { label: 'Start date', value: 'dateStart', visible: true, field: 'dateStart' },
    { label: 'End date', value: 'dateEnd', visible: true, field: 'dateEnd' },
    { label: 'Type', value: 'redirectType', visible: true, field: 'redirectType' },
    { label: 'Target status', value: 'targetStatus', visible: true, field: 'targetStatus' },
    { label: 'Edited by', value: 'userModifiedBy', visible: true, field: 'userModifiedBy' },
  ];

  const COLUMNS_VERSION = 1; // Change this if the defaultColumns are modified

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, updateItemsPerPage] = useState(50);
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedRules, setSelectedRules] = useState<string[]>([]);
  const [selectedRulesWithPolicy, setSelectedRulesWithPolicy] = useState<{ policy: string; docRefId: string }[]>([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [columns, setColumns] = useLocalStorage<RuleTableColumn[]>('config:rules-columns', defaultColumns);
  const [currentColumnsVersion, setCurrentColumnsVersion] = useLocalStorage<number>('config:rules-columns-version', 0);
  const { refreshToken } = useContext(LoginContext);
  const filterMonth = getPreviousMonthAndYear(new Date());
  const isR1Global = policy === 'r1-global';
  const [sortBy, setSortBy] = useState<{ field: string | null; direction: string | null }>({
    field: null,
    direction: 'desc',
  });
  const searchParams = new URLSearchParams(search);
  const sortParam = searchParams.get('sort');
  const multiplePolicies = searchParams.get('policy')?.split(',');
  const isMultiPolicies = multiplePolicies && multiplePolicies?.length > 1;
  const breadcrumbPageName = sortParam ? 'defaultUrl' : '';
  const {
    state: { refetchAfter, sort },
    setSelectedRuleValue,
    setUploadRulesModalValues,
  } = useContext(RulesContext);

  useEffect(() => {
    setSortBy({ field: sort.field, direction: sort.direction });
  }, [sort]);

  useEffect(() => {
    if (sortParam) {
      setSortBy({
        field: 'invocations',
        direction: sortParam === 'high' ? 'desc' : 'asc',
      });
    }
  }, [sortParam]);

  const { setStatusMessage } = useContext(StatusMessageContext);

  const { data, loading } = useFetchRules({
    refetchAfter,
    policy,
    pageLimit: itemsPerPage,
    page: currentPage,
    searchValue,
    sort: `${sort.direction ?? 'desc'}-${sort.field ?? 'dateModified'}`,
    month: filterMonth.month,
    year: filterMonth.year,
    multiplePolicies,
  });
  const { rules, totalRules } = data ?? { rules: [], totalRules: 0 };
  useEffect(() => {
    if (COLUMNS_VERSION !== currentColumnsVersion) {
      setColumns(defaultColumns);
      setCurrentColumnsVersion(COLUMNS_VERSION);
    }
  }, []);

  useEffect(() => {
    if (state) {
      const { title, isVisible, variant, bodyText, actions } = state;

      setStatusMessage({
        title,
        isVisible,
        variant,
        bodyText,
        actions,
      });
    }
  }, [state]);

  const visibleColumns = columns.filter((column) => column.visible);
  const defaultCheckBoxes: CheckboxFields[] = columns
    .map(({ label, value, visible }) => ({
      checked: visible,
      label,
      value,
      id: value,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
  const downloadFile = async (policy: string) => {
    const token = await refreshToken();
    try {
      const res = await request<string>({
        method: 'POST',
        responseType: 'blob',
        url: `/api/redirect/rules/${policy}/download`,
        params: { search: searchValue },
        headers: { Authorization: `Bearer ${token}` },
        data: { docRefIds: selectedRules },
      });
      return FileSaver.saveAs(res.data, `${policy}_rules.xlsx`);
    } catch (e) {
      console.log(e);
    }
  };

  const deleteClick = () => {
    setSelectedRuleValue({});
    setIsDeleteModalOpen(true);
  };

  const toggleSelectAll = () => {
    if (selectedRules.length) {
      return clearSelectedRules();
    }
    const ruleIds = rules.map((rule) => rule.docRefId);
    setSelectedRules(ruleIds);
  };

  const toggleSelectAllWithPolicy = () => {
    if (selectedRulesWithPolicy.length) {
      return clearSelectedRulesWithPolicy();
    }
    const ruleIdsAndPolicies = rules.map((rule) => ({
      docRefId: rule.docRefId,
      policy: findCorrectPolicy(policy, rule.matchURL, isR1Global, country),
    }));
    setSelectedRulesWithPolicy(ruleIdsAndPolicies);
  };

  const onRuleToggle = (docRefId: string, policy: string) => {
    if (isMultiPolicies && policy)
      setSelectedRulesWithPolicy((rulesWithPolicy) => {
        if (rulesWithPolicy.some((rule) => rule.docRefId === docRefId && rule.policy === policy)) {
          return rulesWithPolicy.filter((rule) => rule.docRefId !== docRefId || rule.policy !== policy);
        }
        return Array.from(new Set([...rulesWithPolicy, { docRefId, policy }]));
      });
    setSelectedRules((rules) => {
      if (rules.includes(docRefId)) {
        return rules.filter((ruleId) => ruleId !== docRefId);
      }
      return Array.from(new Set([...rules, docRefId]));
    });
  };

  const clearSelectedRules = () => {
    if (selectedRules.length) {
      setSelectedRules([]);
    }
  };

  const clearSelectedRulesWithPolicy = () => {
    if (selectedRulesWithPolicy.length) {
      setSelectedRulesWithPolicy([]);
    }
  };

  const onRulesColumnsApply = (columnsToDisplay: string[]) =>
    setColumns((prevColumns) => {
      return prevColumns.map((column) => {
        const isColumnVisible = columnsToDisplay.includes(column.value);
        return { ...column, visible: isColumnVisible };
      });
    });
  return (
    <ContainerComp page={breadcrumbPageName}>
      <DeleteRuleModal
        isVisible={isDeleteModalOpen}
        onCancel={() => {
          setIsDeleteModalOpen(false);
        }}
        onSubmit={() => {
          setIsDeleteModalOpen(false);
          isMultiPolicies ? clearSelectedRulesWithPolicy() : clearSelectedRules();
        }}
        selectedRules={isMultiPolicies ? selectedRulesWithPolicy : selectedRules}
        policy={policy}
        multiplePolicy={multiplePolicies}
      />

      <Styled.SearchWrapper>
        <SearchField
          id="rules_search_input"
          data-testid={'rules-search-input'}
          placeholder="Search"
          type="text"
          onLetterSearch={(value) => {
            setSearchValue(value);
            clearSelectedRules();
            setCurrentPage(1);
            isMultiPolicies ? clearSelectedRulesWithPolicy() : clearSelectedRules();
          }}
        />
        <CheckboxGroup onApply={onRulesColumnsApply} defaultCheckboxes={defaultCheckBoxes} />
      </Styled.SearchWrapper>

      <Styled.RulesFilteringWrapper>
        {!multiplePolicies && (
          <>
            <Button
              ssrIcon={arrowDown}
              text={`Download ${selectedRules.length ? `(${selectedRules.length})` : 'all'}`}
              type={'secondary'}
              onClick={() => downloadFile(policy)}
              data-testid={'rules-download-btn'}
              size={'small'}
            />

            <Button
              ssrIcon={arrowUp}
              text={'Upload'}
              type={'secondary'}
              onClick={() => setUploadRulesModalValues({ isOpen: true, country, policy })}
              data-testid={'rules-upload-btn'}
              size={'small'}
            />
          </>
        )}

        <Button
          ssrIcon={trashCan}
          text={`Delete ${
            selectedRules.length || selectedRulesWithPolicy.length
              ? `(${isMultiPolicies ? selectedRulesWithPolicy.length : selectedRules.length})`
              : ''
          }`}
          type={'secondary'}
          onClick={() => deleteClick()}
          disabled={isMultiPolicies ? selectedRulesWithPolicy.length < 1 : selectedRules.length < 1}
          data-testid={'rules-delete-btn'}
          size={'small'}
        />

        <Text tagName="span" bodySize="m">
          {totalRules} items found
        </Text>

        <Pagination
          totalItems={totalRules}
          itemsPerPage={itemsPerPage}
          onPageChange={(pageNumber) => {
            setCurrentPage(pageNumber);
            isMultiPolicies ? clearSelectedRulesWithPolicy() : clearSelectedRules();
          }}
          onItemsPerPageChange={(e) => {
            updateItemsPerPage(Number(e.target.value));
            isMultiPolicies ? clearSelectedRulesWithPolicy() : clearSelectedRules();
          }}
        />
      </Styled.RulesFilteringWrapper>
      <Rules
        selectedRules={isMultiPolicies ? selectedRulesWithPolicy : selectedRules}
        isLoading={loading}
        rules={rules}
        onRuleToggle={onRuleToggle}
        toggleSelectAll={isMultiPolicies ? toggleSelectAllWithPolicy : toggleSelectAll}
        clearSelectedRules={isMultiPolicies ? clearSelectedRulesWithPolicy : clearSelectedRules}
        columns={visibleColumns}
        policy={policy}
        hideActionsIcon={true}
        isMultiPolicy={isMultiPolicies}
      />
    </ContainerComp>
  );
};
