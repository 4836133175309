import React from 'react';
import Text from '@ingka/text';
import { StatusIcon } from 'components/StatusIcon';
import { Column as MetaDataTableColumn } from 'types';
import { MetaDataEntry } from '__generated__/types';
import { AlignedCell } from './styles';

interface MetaDataCellProps {
  entry: MetaDataEntry;
  type: string;
  column: MetaDataTableColumn;
}
interface TdWithTextProps {
  text: string;
  rightAlign?: boolean;
}

const TdWithText: React.FC<TdWithTextProps> = ({ text, rightAlign = false }) => (
  <AlignedCell rightAlign={rightAlign}>
    <Text tagName="span" bodySize="m">
      {text}
    </Text>
  </AlignedCell>
);

export const MetaDataCell: React.FC<MetaDataCellProps> = ({ entry, type, column }) => {
  switch (column.value) {
    case 'pageTitle':
    case 'urlSlug':
    case 'robots':
    case 'author':
      return <TdWithText text={entry?.[column.value] ?? ''} />;
    case 'entryId':
      return <TdWithText text={entry?.id ?? ''} rightAlign />;
    case 'ts':
      return entry?.ts ? <TdWithText text={new Date(entry.ts).toLocaleString()} rightAlign /> : null;
    case 'status':
      return (
        <td>
          <StatusIcon
            statusType={entry.needsReview ? 'needsReview' : type === 'filter' ? 'isPublished' : 'isOptimized'}
            status={!!entry.isOptimized}
          />
        </td>
      );
    default:
      return null;
  }
};
